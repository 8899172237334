<template>
  <div>
    <div>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        class="ml-auto mb-2 mr-2"
        :disabled="typeof $refs['questionnaire-table'] !== 'undefined' && $refs['questionnaire-table'].selectedRows.length < 1"
        @click="handleExportMultiple('csv')"
      >
        Export to csv
      </b-button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        class="ml-auto mb-2 mr-2"
        :disabled="typeof $refs['questionnaire-table'] !== 'undefined' && $refs['questionnaire-table'].selectedRows.length < 1"
        @click="handleExportMultipletypes('csv')"
      >
        Multi-type Export
      </b-button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        class="ml-auto mb-2"
        :disabled="typeof $refs['questionnaire-table'] !== 'undefined' && $refs['questionnaire-table'].selectedRows.length > 0"
        @click="handleExportAll('csv')"
      >
        Export All
      </b-button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="secondary"
        class="ml-1 mb-2 mr-2"
        :disabled="typeof $refs['questionnaire-table'] !== 'undefined' && $refs['questionnaire-table'].selectedRows.length < 1"
        @click="handleExportMultiple('excel')"
      >
        Export Excel
      </b-button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="secondary"
        class="ml-auto mb-2 mr-2"
        :disabled="typeof $refs['questionnaire-table'] !== 'undefined' && $refs['questionnaire-table'].selectedRows.length < 1"
        @click="handleExportMultipletypes('excel')"
      >
        Multi-type Export Excel
      </b-button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="secondary"
        class="ml-auto mb-2"
        :disabled="typeof $refs['questionnaire-table'] !== 'undefined' && $refs['questionnaire-table'].selectedRows.length > 0"
        @click="handleExportAll('excel')"
      >
        Export All Excel
      </b-button>

    </div>
    <!-- table -->
    <vue-good-table
      ref="questionnaire-table"
      :columns="columns"
      :rows="rows"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="handleExportCSV([props.row._id], props.row.kitType)">
                <feather-icon
                  icon="DownloadIcon"
                  class="mr-50"
                />
                <span>Download CSV</span>
              </b-dropdown-item>
              <b-dropdown-item @click="handleExportExcel([props.row._id], props.row.kitType)">
                <feather-icon
                  icon="DownloadIcon"
                  class="mr-50"
                />
                <span>Download Excel</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="props.row.kitType === 'covid'"
                :disabled="!props.row.bloodReports"
                @click="downloadBloodReports(props.row._id)"
              >
                <feather-icon
                  icon="DownloadIcon"
                  class="mr-50"
                />
                <span>Download Blood Reports</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div></template>
    </vue-good-table>
  </div></template>

<script>
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
// eslint-disable-next-line import/no-extraneous-dependencies
import { formatDate } from '@/@core/utils/filter'

export default {
  components: {
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 20,
      columns: [
        {
          label: 'DU Barcode',
          field: 'duBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'ST Barcode',
          field: 'stBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'SA Barcode',
          field: 'saBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'COR Barcode',
          field: 'corBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Type',
          field: 'questionnaireType',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Kit Type',
          field: 'kitType',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
          formatFn: kitType => kitType.replace(/^\w/, c => c.toUpperCase()),
        },
        {
          label: 'Progress',
          field: 'progress',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
    }
  },
  mounted() {
    this.getTableData()
  },
  methods: {
    getTableData() {
      this.showLoading()
      this.$http.get('/questionnaireResponse', { params: { queryParams: {} } }).then(response => {
        this.rows = response.data.list
      }).catch(this.handleError).finally(this.closeLoading)
    },
    handleExportMultiple(type) {
      /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
      const { selectedRows } = this.$refs['questionnaire-table']
      if (type === 'csv') {
        this.handleExportCSV(selectedRows.map(selectedRow => selectedRow._id), selectedRows.map(selectedRow => selectedRow.kitType)[0])
      } else if (type === 'excel') {
        this.handleExportExcel(selectedRows.map(selectedRow => selectedRow._id), selectedRows.map(selectedRow => selectedRow.kitType)[0])
      }
    },
    handleExportMultipletypes(type) {
    /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
      const { selectedRows } = this.$refs['questionnaire-table']
      if (type === 'csv') {
        this.handleExportCSVMultitype(selectedRows.map(selectedRow => selectedRow._id))
      } else if (type === 'excel') {
        this.handleExportExcelMultitype(selectedRows.map(selectedRow => selectedRow._id))
      }
    },
    handleExportAll(type) {
      if (type === 'csv') {
        this.$http({
          method: 'post',
          url: 'questionnaireResponse/exportCSV',
          responseType: 'blob',
          data: {
            columnFilters: { },
          },
        }).then(resp => {
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(resp.data)
          link.setAttribute('download', `${formatDate(Date.now()).replace(/[,\s]+/g, '-')}_responses.csv`) // or any other extension
          document.body.appendChild(link)
          link.click()
          this.$bvToast.toast('Responses Exported successfully', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
        }).catch(this.handleError)
      } else if (type === 'excel') {
        this.$http({
          method: 'post',
          url: 'questionnaireResponse/exportExcel',
          responseType: 'blob',
          data: {
            columnFilters: { },
          },
        }).then(resp => {
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(resp.data)
          link.setAttribute('download', `${formatDate(Date.now()).replace(/[,\s]+/g, '-')}_responses.xlsx`) // or any other extension
          document.body.appendChild(link)
          link.click()
          this.$bvToast.toast('Responses Exported successfully', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
        }).catch(this.handleError)
      }
    },
    handleExportCSVMultitype(ids) {
      this.$http({
        method: 'post',
        url: 'questionnaireResponse/exportCSV',
        responseType: 'blob',
        data: {
          columnFilters: { _id: { $in: ids } },
        },
      }).then(resp => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(resp.data)
        link.setAttribute('download', `${formatDate(Date.now()).replace(/[,\s]+/g, '-')}_responses.csv`) // or any other extension
        document.body.appendChild(link)
        link.click()
        this.$bvToast.toast('Responses Exported successfully', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      }).catch(this.handleError)
    },
    handleExportExcelMultitype(ids) {
      this.$http({
        method: 'post',
        url: 'questionnaireResponse/exportExcel',
        responseType: 'blob',
        data: {
          columnFilters: { _id: { $in: ids } },
        },
      }).then(resp => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(resp.data)
        link.setAttribute('download', `${formatDate(Date.now()).replace(/[,\s]+/g, '-')}_responses.xlsx`) // or any other extension
        document.body.appendChild(link)
        link.click()
        this.$bvToast.toast('Responses Exported successfully', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      }).catch(this.handleError)
    },
    handleExportCSV(ids, kitType) {
      this.$http({
        method: 'post',
        url: `questionnaireResponse/${kitType}/exportCSV`,
        responseType: 'blob',
        data: {
          columnFilters: { _id: { $in: ids } },
        },
      }).then(resp => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(resp.data)
        link.setAttribute('download', `${formatDate(Date.now()).replace(/[,\s]+/g, '-')}_responses.csv`) // or any other extension
        document.body.appendChild(link)
        link.click()
        this.$bvToast.toast('Responses Exported successfully', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      }).catch(this.handleError)
    },
    handleExportExcel(ids, kitType) {
      this.$http({
        method: 'post',
        url: `questionnaireResponse/${kitType}/exportExcel`,
        responseType: 'blob',
        data: {
          columnFilters: { _id: { $in: ids } },
        },
      }).then(resp => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(resp.data)
        link.setAttribute('download', `${formatDate(Date.now()).replace(/[,\s]+/g, '-')}_responses.xlsx`) // or any other extension
        document.body.appendChild(link)
        link.click()
        this.$bvToast.toast('Responses Exported successfully', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      }).catch(this.handleError)
    },
    // eslint-disable-next-line no-unused-vars
    downloadBloodReports(_id) {
      const selectedBarcodeData = this.rows.filter(row => row._id === _id)[0]
      selectedBarcodeData.bloodReports.forEach(report => {
        this.$http({
          method: 'get',
          url: report,
          responseType: 'blob',
        }).then(resp => {
          const link = document.createElement('a')
          const url = new URL(decodeURIComponent(resp.config.url))
          const filename = url.pathname.split('/').reverse()[0]
          link.href = window.URL.createObjectURL(resp.data)
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
        }).catch(this.handleError)
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
